import cn from 'classnames';
import { GarmentMeasurementInput, GetGarmentMeasurementQuery, Maybe } from '@graphql';
import { Icon, Tooltip, UnitInput } from 'components';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useMeasurementUnit } from 'modules/common/MeasurementUnit';
import { validateNumber } from 'helpers/measurement-helpers';
import { ArrayElement } from 'types/common';

export type GarmentField = ArrayElement<GetGarmentMeasurementQuery['settings']['measurement']['garment']['fields']>;

type RowProps = {
  field: GarmentField;
  index: number;
  measurementUnitInfo: ReturnType<typeof useMeasurementUnit>['measurementUnitInfo'];
  convertFromServerUnit: ReturnType<typeof useMeasurementUnit>['convertFromServerUnit'];
  onOpenGarmentPanel: (garmentField: GarmentField) => void;
  allFields?: GarmentField[];
};

const GarmentMeasureFieldRow = ({ field, index, measurementUnitInfo, convertFromServerUnit, onOpenGarmentPanel, allFields }: RowProps) => {
  const {
    control,
    register,
    formState: { errors },
    watch,
    getValues,
  } = useFormContext<GarmentMeasurementInput>();
  const error = errors?.fields && errors.fields[index]?.value;
  const minValue = convertFromServerUnit(field.range.min);
  const maxValue = convertFromServerUnit(field.range.max);
  const firstButtonTweak = watch(`fields.${index}.value`) || 0;

  const validateShirtFrontBackLength = ({ key }: { key: string }) => {
    if ((key === 'SHIRT_BACK_LENGTH' || key === 'SHIRT_FRONT_LENGTH') && allFields?.length > 0) {
      const frntLengthBsIndex = allFields.findIndex(({ key }) => key === 'SHIRT_FRONT_LENGTH');
      const backLengthBsIndex = allFields.findIndex(({ key }) => key === 'SHIRT_BACK_LENGTH');
      if (frntLengthBsIndex >= 0 && backLengthBsIndex >= 0) {
        const frntLengthValue = convertFromServerUnit(Number(getValues(`fields.${frntLengthBsIndex}.value`)));
        const backLengthValidationValue = convertFromServerUnit(Number(getValues(`fields.${backLengthBsIndex}.value`)));
        return Math.abs(frntLengthValue - backLengthValidationValue) <= 1.5
          ? true
          : `Front length must not be more then 1.5(${measurementUnitInfo.label}) from Back length`;
      }
    }
    return true;
  };

  return (
    <div key={field.key} className={cn('relative items-center py-2')}>
      <input hidden value={field.key} {...register(`fields.${index}.key` as const)} />
      <div
        className={
          (field.key == 'SHIRT_BACK_LENGTH' || field.key == 'SHIRT_FRONT_LENGTH') && error?.type == 'validate'
            ? cn('flex items-center mb-4')
            : cn('flex items-center mb-2')
        }
      >
        <label className="flex-1">{field.name}</label>
        {field.key != 'JACKET_FIRST_BUTTON' ? (
          <Controller
            name={`fields.${index}.value` as const}
            control={control}
            rules={{
              required: `Enter a ${measurementUnitInfo.shortName} value (2dp max)`,
              validate: (v) => (validateNumber(v) || `Value must be a number (2dp max)`) && validateShirtFrontBackLength({ key: field.key }),
              min: minValue,
              max: maxValue,
            }}
            render={({ field: formField }) => (
              <UnitInput
                className="w-24"
                unit={measurementUnitInfo.label}
                htmlProps={{
                  id: field.name,
                  ...formField,
                  onChange: (e) => {
                    const rawUnitValue = e.target.value;
                    if (rawUnitValue === '' || validateNumber(rawUnitValue, 2)) {
                      formField.onChange(e);
                    }
                  },
                }}
                errorMessage={error?.message}
                showError={false}
              />
            )}
          />
        ) : (
          <Controller
            name={`fields.${index}.value` as const}
            control={control}
            rules={{
              validate: (v) => {
                if (!validateNumber(v)) return `Vzalue must be a number (2dp max)`;
                const val = convertFromServerUnit(getValues(`fields.${index}.value`));
                if (val != 0) {
                  if (val > maxValue || val < minValue) {
                    return `Must be between ${minValue} - ${maxValue}cm`;
                  }
                }

                return validateShirtFrontBackLength({ key: field.key });
              },
            }}
            render={({ field: formField }) => (
              <Tooltip
                content={
                  <span className="text-white">
                    Top-button measurement is based on block size of 1Btn/2Btn normal/peak:
                    <br /> <br />
                    <ul className="list-disc ml-4  space-y-2">
                      <li>For 3Btn/2.5Btn normal/peak: Top button measurement should be 9cm less than 1Btn/2Btn normal/peak lapel.</li>
                      <li>For 2.5Btn normal/peak: Top Button measurement should be 2.5cm less than 1Btn/2Btb normal/peak lapel.</li>
                      <li>For 6Btn/4Btn normal/peak: Top Button measurement should be 3cm greater than 1Btn/2Btn normal/peak lapel.</li>
                    </ul>
                  </span>
                }
                className="col-span-1 cursor-pointer"
                variant="dark"
                contentWidth="350px"
              >
                <UnitInput
                  className="w-24"
                  unit={measurementUnitInfo.label}
                  htmlProps={{
                    id: field.name,
                    ...formField,
                    onChange: (e) => {
                      const rawUnitValue = e.target.value;
                      if (rawUnitValue === '' || validateNumber(rawUnitValue, 2)) {
                        formField.onChange(e);
                      }
                    },
                  }}
                  errorMessage={error?.message}
                  showError={false}
                />
              </Tooltip>
            )}
          />
        )}
        <div>
          {field?.info ? (
            <button type="button" className="self-center ml-5" onClick={() => onOpenGarmentPanel(field)}>
              <Icon icon="info-stroke" width={12} height={12} />
            </button>
          ) : (
            <div className="w-8" />
          )}
        </div>
      </div>
      {error && (
        <div className="absolute bottom-0 inline-flex text-red-500 text-xs">
          {(() => {
            switch (error.type) {
              case 'required':
                return error.message;
              case 'max':
                return `Must be lower than ${maxValue}${measurementUnitInfo.shortName}`;
              case 'min':
                return `Must be greater than ${minValue}${measurementUnitInfo.shortName}`;
              case 'validate':
                return error.message;
              default:
                return '';
            }
          })()}
        </div>
      )}
    </div>
  );
};

type Props = {
  data: Maybe<GetGarmentMeasurementQuery>;
  onOpenGarmentPanel: (garmentField: GarmentField) => void;
  measurementUnitInfo: ReturnType<typeof useMeasurementUnit>['measurementUnitInfo'];
  convertFromServerUnit: ReturnType<typeof useMeasurementUnit>['convertFromServerUnit'];
};

export const GarmentMeasurementFields = ({ data, measurementUnitInfo, convertFromServerUnit, onOpenGarmentPanel }: Props) => {
  const [col1, col2] = useMemo(() => {
    const fields = data?.settings?.measurement.garment.fields || [];
    const half = Math.ceil(fields.length / 2);

    return [fields.slice(0, half), fields.slice(half)];
  }, [data]);

  return (
    <div className="flex">
      <div className="w-1/2 border-r pr-8">
        {col1.map((field, index) => (
          <GarmentMeasureFieldRow
            key={field.key}
            field={field}
            measurementUnitInfo={measurementUnitInfo}
            convertFromServerUnit={convertFromServerUnit}
            index={index}
            onOpenGarmentPanel={onOpenGarmentPanel}
            allFields={[...col1, ...col2]}
          />
        ))}
      </div>
      <div className="w-1/2 pl-8">
        {col2.map((field, index) => (
          <GarmentMeasureFieldRow
            key={field.key}
            field={field}
            measurementUnitInfo={measurementUnitInfo}
            convertFromServerUnit={convertFromServerUnit}
            index={index + col1.length}
            onOpenGarmentPanel={onOpenGarmentPanel}
            allFields={[...col1, ...col2]}
          />
        ))}
      </div>
    </div>
  );
};
