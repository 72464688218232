import { OrderItemSummaryDrawerFragmentFragment, MeasurementType } from '@graphql';
import { Icon } from 'components';
import { ArrayElement } from 'types/common';
import { getMeasurementWholeLabel, getMeasurementWholeValue } from 'helpers/measurement-helpers';
import { prefixNumber } from 'helpers/string-helpers';
import { useMeasurementUnit } from 'modules/common/MeasurementUnit';

export const OrderItemSummaryDrawerDesignInfo = ({ design }: { design: ArrayElement<OrderItemSummaryDrawerFragmentFragment['designs']> }) => {
  const {
    convertFromServerUnit,
    measurementUnitInfo: { label: inputLabel },
  } = useMeasurementUnit();
  const measurement = design?.measurement;

  return (
    <>
      <div className="mb-20 pl-4">
        <div className="text-lg font-medium border-b border-gray-200 pb-8">Design options</div>
        <div className="mt-6">
          {!design?.options.length && <div>There are no chosen design options.</div>}
          {Boolean(design?.options.length) && (
            <div className="grid grid-cols-3 gap-8">
              {design?.options
                .filter((o) => o?.designTypeOffering)
                .map((option, count) => {
                  return (
                    <div className="flex items-center" key={`options${count}`}>
                      <Icon icon="scissors" className="mr-4" />
                      <div className="colspan-1">{`${option.designTypeOffering?.name} - ${option.name || option.value || 'no option selected'}`}</div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
      <div className="mb-20 pl-4">
        <div className="text-lg font-medium border-b border-gray-200 pb-8">Measurements</div>
        <div className="mt-6">
          {!measurement && <div>There is no chosen measurement.</div>}
          {measurement && (
            <>
              <div className="text-lg font-medium flex items-center">
                {measurement?.name || ''}
                <div className="text-xs rounded-full bg-gray-300 text-gray-800 uppercase flex items-center px-2 mr-4 font-medium leading-4 h-6 ml-2">
                  {measurement?.type?.name}
                </div>
              </div>
              {measurement?.size && <div>Size: {measurement.size}</div>}
              <ul className="grid grid-cols-2 gap-6 mt-6">
                {[MeasurementType.Body, MeasurementType.Garment, MeasurementType.Simplified, MeasurementType.Applied].includes(
                  measurement?.type?.key
                ) && (
                  <>
                    {measurement?.finishedMeasurementFields.map((field) => {
                      const tweak = measurement.tweaks?.find((tweak) => tweak.__typename === 'GarmentMeasurementTweak' && tweak.key === field.key);

                      // some values are stored 'halved' e.g. Half Waist
                      // convert from 'Half' values e.g. match measurement creation process UI
                      const label = getMeasurementWholeLabel(field.label);
                      const value = getMeasurementWholeValue(field.key, field.value);

                      return (
                        <li key={`${field.key}${field.label}`}>
                          <>
                            {label}: {convertFromServerUnit(value, 1)}
                            {inputLabel}{' '}
                            {tweak?.__typename === 'GarmentMeasurementTweak' && tweak?.value
                              ? `(${prefixNumber(convertFromServerUnit(getMeasurementWholeValue(tweak.key, tweak.value)))}${inputLabel})`
                              : null}
                          </>
                        </li>
                      );
                    })}
                  </>
                )}
                {[MeasurementType.TryOn].includes(measurement?.type?.key) && (
                  <>
                    {measurement?.tweaks.map((tweak, count) => {
                      if (tweak.__typename === 'TryOnMeasurementTweak') {
                        return (
                          <li key={`measurement.tweaks${count}`}>
                            <span className="font-semibold">{tweak?.typeName} - </span>
                            {`${tweak?.itemName}: ${tweak?.option ? `${convertFromServerUnit(Number(tweak.option))}${inputLabel}` : ''}`}
                          </li>
                        );
                      }
                    })}
                  </>
                )}
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
};
