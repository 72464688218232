import { NextRouter } from 'next/router';
import { toast } from 'react-toastify';
import { startCase, camelCase } from 'lodash';

import { ActiveSessionInfo } from 'providers/active-session.provider';
import {
  GetTryOnMeasurementQueryResult,
  TryOnMeasurementTweakInput,
  TweakGroup,
  GarmentCategory,
  TryOnMeasurementItem,
  MeasurementType,
  MeasurementUnit,
} from '@graphql';
import { ArrayElement } from 'types/common';
import mixpanelService from 'services/mixpanel.service';

export const parseMeasurementLabel = (label: string) => {
  if (!label.toLowerCase().includes('girth')) {
    return label;
  }
  if (label.includes('Girth')) {
    return label.replace('Girth', 'Waist');
  }
  if (label.includes('girth')) {
    return label.replace('girth', 'waist');
  }
};

export const roundToDp = (num: number, dp = 2) => (isNaN(num) ? 0 : num === 0 ? 0 : Math.round(num * Math.pow(10, dp)) / Math.pow(10, dp));

export const getAvailableFits = ({
  tailor,
  data,
  garmentCategory,
}: {
  tailor: ActiveSessionInfo['tailor'];
  data: GetTryOnMeasurementQueryResult['data'];
  garmentCategory: GarmentCategory;
}) => {
  const tailorFits = tailor?.config?.availableFits?.[garmentCategory];
  return (
    data?.settings.measurement.tryOn.fits.filter((f) => (!tailorFits ? true : tailorFits.includes(f.key) || data.measurement?.fit.key === f.key)) ||
    []
  );
};

export const getDefaultFitValue = (props: {
  tailor: ActiveSessionInfo['tailor'];
  garmentCategory: GarmentCategory;
  data: GetTryOnMeasurementQueryResult['data'];
}) => {
  const fits = getAvailableFits({ ...props });

  const { garmentCategory } = props;

  if (garmentCategory === GarmentCategory.Pants) {
    return fits.find(({ key }) => key === 'SLIM_V2')?.key || null;
  }

  if (garmentCategory === GarmentCategory.Jacket || garmentCategory === GarmentCategory.Vest) {
    return fits.find(({ key }) => key === 'SLIM')?.key || null;
  }

  return null;
};

const conflictingTweaks: Partial<Record<TryOnMeasurementItem, keyof typeof TryOnMeasurementItem>> = {
  SHIRT_V2_SHORTEN_SLEEVE_L: 'ShirtV2LengthenSleeveR',
  SHIRT_V2_SHORTEN_SLEEVE_R: 'ShirtV2LengthenSleeveL',
  PANTS_SHORTEN_LEG_L: 'PantsLengthenLegR',
  PANTS_SHORTEN_LEG_R: 'PantsLengthenLegL',
  JACKET_SHORTEN_SLEEVE_LENGTH_L: 'JacketLengthenSleeveLengthR',
  JACKET_SHORTEN_SLEEVE_LENGTH_R: 'JacketLengthenSleeveLengthL',
  OVERCOAT_SHORTEN_SLEEVE_L: 'OvercoatLengthenSleeveR',
  OVERCOAT_SHORTEN_SLEEVE_R: 'OvercoatLengthenSleeveL',
  TRENCH_COAT_SHORTEN_SLEEVE_L: 'TrenchCoatLengthenSleeveR',
  TRENCH_COAT_SHORTEN_SLEEVE_R: 'TrenchCoatLengthenSleeveL',
  WOMEN_JACKET_SHORTEN_SLEEVE_LENGTH_L: 'WomenJacketLengthenSleeveLengthR',
  WOMEN_JACKET_SHORTEN_SLEEVE_LENGTH_R: 'WomenJacketLengthenSleeveLengthL',
  MORNING_COAT_SHORTEN_SLEEVE_LENGTH_L: 'MorningCoatLengthenSleeveLengthR',
  MORNING_COAT_SHORTEN_SLEEVE_LENGTH_R: 'MorningCoatLengthenSleeveLengthL',
  HYBRID_JACKET_SHORTEN_SLEEVE_LENGTH_L: 'HybridJacketLengthenSleeveLengthR',
  HYBRID_JACKET_SHORTEN_SLEEVE_LENGTH_R: 'HybridJacketLengthenSleeveLengthL',
  SAFARI_JACKET_SHORTEN_SLEEVE_LENGTH_L: 'SafariJacketLengthenSleeveLengthR',
  SAFARI_JACKET_SHORTEN_SLEEVE_LENGTH_R: 'SafariJacketLengthenSleeveLengthL',
  BOMBER_SHORTEN_SLEEVE_LENGTH_L: 'BomberLengthenSleeveLengthR',
  BOMBER_SHORTEN_SLEEVE_LENGTH_R: 'BomberLengthenSleeveLengthL',
  BLOUSON_SHORTEN_SLEEVE_LENGTH_L: 'BlousonLengthenSleeveLengthR',
  BLOUSON_SHORTEN_SLEEVE_LENGTH_R: 'BlousonLengthenSleeveLengthL',
  FLIGHT_SHORTEN_SLEEVE_LENGTH_L: 'FlightLengthenSleeveLengthR',
  FLIGHT_SHORTEN_SLEEVE_LENGTH_R: 'FlightLengthenSleeveLengthL',
  HARRINGTON_SHORTEN_SLEEVE_LENGTH_L: 'HarringtonLengthenSleeveLengthR',
  HARRINGTON_SHORTEN_SLEEVE_LENGTH_R: 'HarringtonLengthenSleeveLengthL',
  CASUAL_SHIRT_SHORTEN_SLEEVE_L: 'CasualShirtLengthenSleeveR',
  CASUAL_SHIRT_SHORTEN_SLEEVE_R: 'CasualShirtLengthenSleeveL',
  ACTIVE_SHORTEN_SLEEVE_LENGTH_L: 'ActiveLengthenSleeveLengthR',
  ACTIVE_SHORTEN_SLEEVE_LENGTH_R: 'ActiveLengthenSleeveLengthL',
  //TODO:need to confirm on this
  //SHIRT_V3_SHORTEN_SLEEVE_L: 'ShirtV3LengthenSleeveR',
  //SHIRT_V3_SHORTEN_SLEEVE_R: 'ShirtV3LengthenSleeveL',
};

export type ConflictItem = {
  type: string;
  item: string;
  option: string;
};

export type Conflict = {
  conflictA: ConflictItem;
  conflictB: ConflictItem;
};

export const checkConflicts = ({
  tweaks,
  data,
}: {
  tweaks: TryOnMeasurementTweakInput[];
  data: GetTryOnMeasurementQueryResult['data'];
}): Conflict[] => {
  const conflicts = [];

  const getSetting = (tweak: ArrayElement<typeof tweaks>) =>
    data.settings.measurement.tryOn.tweaks.find((tweakSetting) => tweakSetting.items.map((item) => item.key).includes(tweak.itemKey));

  const getItem = (setting: ReturnType<typeof getSetting>, tweak: ArrayElement<typeof tweaks>) =>
    setting.items.find((item) => item.key === tweak.itemKey);

  Object.keys(conflictingTweaks).forEach((conflict) => {
    const tweak1 = tweaks.find((tweak) => tweak.itemKey === conflict);
    const tweak2 = tweaks.find((tweak) => tweak.itemKey === TryOnMeasurementItem[conflictingTweaks[conflict]]);

    if (tweak1 && tweak2) {
      const conflict1Setting = getSetting(tweak1);
      const conflict2Setting = getSetting(tweak2);

      const conflictItem1 = getItem(conflict1Setting, tweak1);
      const conflictItem2 = getItem(conflict2Setting, tweak2);

      conflicts.push({
        conflictA: {
          type: conflict1Setting.name,
          item: conflictItem1.name,
          option: tweak1.option,
        },
        conflictB: {
          type: conflict2Setting.name,
          item: conflictItem2.name,
          option: tweak2.option,
        },
      });
    }
  });

  return conflicts;
};

export const tweakGroupDisplayName: Record<TweakGroup, string> = {
  [TweakGroup.Build]: 'Stature & Build',
  [TweakGroup.Horizontals]: 'Horizontals',
  [TweakGroup.Length]: 'Length',
  [TweakGroup.Others]: 'Others',
  [TweakGroup.Back]: 'Back',
  [TweakGroup.Side]: 'Side',
  [TweakGroup.Front]: 'Front',
  [TweakGroup.Waist]: 'Waist',
};

/* valid input value to be a number and has max `decimalPlaces` decimal places */
export const validateNumber = (value: number | string, decimalPlaces: number | null = 2) => {
  if (typeof value === 'string' && value.replace(/\s/g, '') === '') return false;

  const valueAsNumber = Number(value);

  if (isNaN(valueAsNumber)) return false;

  const isInteger = valueAsNumber % 1 === 0;

  if (isInteger) return true;

  if (decimalPlaces) {
    const [, decimal] = valueAsNumber.toString().split('.');

    return decimal.length <= decimalPlaces ? true : false;
  }
  return true;
};

export const getUnitLabel = (unit: MeasurementUnit): string => {
  switch (unit) {
    case MeasurementUnit.Cm:
      return 'cm';
    case MeasurementUnit.Inch:
      return '″';
  }
};

export const getUnitShortName = (unit: MeasurementUnit): string => {
  switch (unit) {
    case MeasurementUnit.Cm:
      return 'cm';
    case MeasurementUnit.Inch:
      return 'in';
  }
};

export const getUnitLongName = (unit: MeasurementUnit): string => {
  switch (unit) {
    case MeasurementUnit.Cm:
      return 'Metric';
    case MeasurementUnit.Inch:
      return 'Imperial';
  }
};
const getDivisor = (unitFrom: MeasurementUnit, unitTo: MeasurementUnit): number => {
  switch (`${unitFrom}->${unitTo}` as const) {
    case `CM->INCH`:
      return 2.54;
    case 'INCH->CM':
      return 1 / 2.54;
    case `CM->CM`:
    case 'INCH->INCH':
      return 1;
  }
};

export const getUnitValue = (
  value: number,
  unitFrom: MeasurementUnit = MeasurementUnit.Cm,
  unitTo: MeasurementUnit = MeasurementUnit.Cm,
  decimalPlaces = 2
) => {
  const convertedValue = value / getDivisor(unitFrom, unitTo);

  return roundToDp(convertedValue, decimalPlaces);
};

type Toast = typeof toast;

interface RedirectProps {
  customerId: string;
  orderId?: string;
  designId?: string;
  newMeasurementId?: string;
  measurementType?: MeasurementType;
}

export const useOnComplete = ({ router, toast }: { router: NextRouter; toast: Toast }) => {
  return ({ measurementId, ...props }: { measurementId: string } & RedirectProps) => {
    if (measurementId) {
      toast.success('Measurement updated.');
    } else {
      toast.success('Measurement created.');
    }

    mixpanelService.track(measurementId ? 'MEASUREMENT_UPDATE' : 'MEASUREMENT_CREATE', {
      measurementId,
      type: MeasurementType.Body,
    });

    // if `orderId` was passed redirect back to order page
    redirectFromMeasurements({ router, ...props });
  };
};

export const useOnError = (toast: Toast) => {
  return (error) => {
    toast.error('Something went wrong, please try again.');

    console.error(error);
  };
};

export const useOnRedirect = (router: NextRouter) => (props: RedirectProps) => redirectFromMeasurements({ router, ...props });

export const redirectFromMeasurements = ({ router, customerId, orderId, designId, newMeasurementId }: { router: NextRouter } & RedirectProps) => {
  if (orderId) {
    if (designId && newMeasurementId) {
      router.push(`/orders/${orderId}?designId=${designId}&measurementId=${newMeasurementId}`);
    } else {
      router.push(`/orders/${orderId}`);
    }
  } else {
    router.push(`/customers/${customerId}`);
  }
};

export const getDefaultNameForMeasurement = ({ garmentCategory, dateStr }: { garmentCategory?: GarmentCategory; dateStr: string }) => {
  const garmentText = garmentCategory ? startCase(camelCase(`${garmentCategory} `)).replace(/ /g, '') : '';
  const defaultText = `${garmentCategory ? garmentText : ''} Measurement ${dateStr}`;
  return defaultText.trim();
};

export const getMeasurementWholeLabel = (label: string) => label.replace('Half', '').trim();
export const getMeasurementWholeValue = (key: string, value: number) => (key.indexOf('HALF') > -1 ? value * 2 : value);
