import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  GarmentCategory,
  GarmentFieldSetting,
  GarmentFitSetting,
  GetGarmentMeasurementQuery,
  GarmentMeasurementInput,
  MeasurementUnit,
} from '@graphql';
import { Button } from 'modules/common/components';
import { Icon, Input, Select, SlideoverPanel, Tooltip } from 'components';
import { MeasurementFormBlockDisplay, MeasurementFormBlockDisplayHeading } from 'modules/measurements/components/MeasurementFormBlockDisplay';
import { getUnitLongName } from 'helpers/measurement-helpers';
import { ArrayElement } from 'types/common';
import { GarmentInfo } from '../GarmentInfo';
import { useMeasurementUnit } from 'modules/common/MeasurementUnit';
import { getBlockMeasurement, getBlockRange, getDependantMeasurementFieldsMapping } from './helpers';
import { DependingFieldSetting } from './types';
import { MeasurementSettingField } from './MeasurementSettingFieldComponents';

type GarmentField = ArrayElement<GetGarmentMeasurementQuery['settings']['measurement']['garment']['fields']>;

interface Props {
  garmentCategory: GarmentCategory;
  fields: GarmentFieldSetting[];
  fits: GarmentFitSetting[];
  shouldValidateSleeveLength?: boolean;
}

export const SimplifiedMeasurements = ({ garmentCategory, fields, fits, shouldValidateSleeveLength }: Props) => {
  const { measurementUnitInfo, updateMeasurementUnit, convertFromServerUnit } = useMeasurementUnit();
  const {
    register,
    watch,
    formState: { errors },
    setValue,
    getValues,
    trigger,
    getFieldState,
  } = useFormContext<GarmentMeasurementInput>();
  const fit = watch('fit');
  const size = watch('size');
  const [open, setOpen] = useState(false);
  const [showFirstButtonTooltip, setshowFirstButtonTooltip] = useState(false);
  const [activeGarment, setActiveGarment] = useState<GarmentField>(null);
  const decimalPrecision = garmentCategory === 'TAL_SHORT_SLEEVE_SHIRT' ? 3 : undefined; // This decimal precision value has to come from new manufacturer's garment offering config, hard-coded for now considering urgency.

  const getErrorMessage = (type: string | undefined, blockRange: { minRange: number; maxRange: number }) => {
    const firstType = Array.isArray(type) ? type[0] : type;
    if (firstType === 'min') {
      return `Must be greater than ${convertFromServerUnit(blockRange.minRange, decimalPrecision)}${measurementUnitInfo.shortName}`;
    } else if (firstType === 'max') {
      return `Must be lower than ${convertFromServerUnit(blockRange.maxRange, decimalPrecision)}${measurementUnitInfo.shortName}`;
    } else if (firstType === 'sleeve-missmatch') {
      return 'Sleeve Outseam (Left) tweak must be the same as Sleeve Outseam (Right) tweak.';
    } else if (firstType === 'front-back-length-missmatch') {
      return `Front length must not be more then 1.5(${measurementUnitInfo.label}) from Back length`;
    }
    return null;
  };

  const dependingFieldMapping = useMemo(() => getDependantMeasurementFieldsMapping(fields), [fields]);

  useEffect(() => {
    const subs = watch((_, { name: dependableFieldName }) => {
      const calculatedFieldName: DependingFieldSetting[] | undefined = dependingFieldMapping[dependableFieldName];
      const tweakValue = getValues(dependableFieldName);
      if (calculatedFieldName?.length > 0 && typeof tweakValue === 'number') {
        calculatedFieldName.forEach(({ fieldName }) => setValue(fieldName, tweakValue));
      }
    });
    return () => subs.unsubscribe();
  }, []);

  const nextMeasurementUnit = measurementUnitInfo.current === MeasurementUnit.Cm ? MeasurementUnit.Inch : MeasurementUnit.Cm;
  const handleTweakChange = async (currentTarget: EventTarget & HTMLSelectElement, key, idx) => {
    if (key === 'JACKET_FIRST_BUTTON') {
      if (currentTarget?.value != '0') {
        setshowFirstButtonTooltip(true);
      } else {
        setshowFirstButtonTooltip(false);
      }
    }
    const num = Number(currentTarget?.value);
    setValue(`tweaks.${idx}.value`, num);
  };

  const isThereDependableFields = Object.keys(dependingFieldMapping).length > 0;
  const checkBBIndiaSleeveLength = ({ garmentCat, key }: { garmentCat: GarmentCategory; key: string }) => {
    if ([GarmentCategory.TalShortSleeveShirt, GarmentCategory.TalShirt].includes(garmentCat)) {
      const leftSleeveIndex = fields?.findIndex((x) => x.key === 'BB_SHORT_SLEEVE_SHIRT_SLEEVE_LENGTH_LEFT');
      const rightSleeveIndex = fields?.findIndex((x) => x.key === 'BB_SHORT_SLEEVE_SHIRT_SLEEVE_LENGTH_RIGHT');

      if (leftSleeveIndex >= 0 && rightSleeveIndex >= 0) {
        const leftSleeveVal = getValues(`tweaks.${leftSleeveIndex}.value`);
        const rightSleeveVal = getValues(`tweaks.${rightSleeveIndex}.value`);
        if (leftSleeveVal != rightSleeveVal) {
          return false;
        }
      }
      if (key === 'BB_SHORT_SLEEVE_SHIRT_SLEEVE_LENGTH_LEFT') {
        const rightSleeveFieldState = getFieldState(`tweaks.${rightSleeveIndex}.value`);
        if (rightSleeveFieldState.error?.message === 'sleeve-missmatch') {
          setTimeout(() => trigger(`tweaks.${rightSleeveIndex}.value`), 0);
        }
      } else {
        const leftSleeveFieldState = getFieldState(`tweaks.${leftSleeveIndex}.value`);
        if (leftSleeveFieldState.error?.message === 'sleeve-missmatch') {
          setTimeout(() => trigger(`tweaks.${leftSleeveIndex}.value`), 0);
        }
      }
      return true;
    }
  };

  const validateShirtFrontBackLength = ({ key }: { key: string }) => {
    if ((key === 'SHIRT_BACK_LENGTH' || key === 'SHIRT_FRONT_LENGTH') && fields?.length > 0) {
      const frntLengthBsIndex = fields.findIndex(({ key }) => key === 'SHIRT_FRONT_LENGTH');
      const backLengthBsIndex = fields.findIndex(({ key }) => key === 'SHIRT_BACK_LENGTH');
      if (frntLengthBsIndex >= 0 && backLengthBsIndex >= 0) {
        const frntLengthField = fields[frntLengthBsIndex];
        const backLengthField = fields[backLengthBsIndex];
        const frntLengthTweak = getValues(`tweaks.${frntLengthBsIndex}.value`);
        const frntLengthBlock = getBlockMeasurement(fits, fit, size, frntLengthField);
        const frntLengthBlockValue = frntLengthBlock?.value * (frntLengthField?.isHalved ? 2 : 1);
        const frntLengthFinishedVal = convertFromServerUnit(frntLengthBlockValue + frntLengthTweak * (frntLengthField?.isHalved ? 2 : 1));

        const backLengthTweak = getValues(`tweaks.${backLengthBsIndex}.value`);
        const backLengthBlock = getBlockMeasurement(fits, fit, size, backLengthField);
        const backLengthBlockValue = backLengthBlock?.value * (backLengthField?.isHalved ? 2 : 1);
        const backLengthFinishedVal = convertFromServerUnit(backLengthBlockValue + backLengthTweak * (backLengthField?.isHalved ? 2 : 1));
        return Math.abs(frntLengthFinishedVal - backLengthFinishedVal) <= 1.5 ? true : 'front-back-length-missmatch';
      }
    }
    return true;
  };

  return (
    <>
      <div className="flex mb-4">
        <Button
          className="ml-auto"
          size="xs"
          variant="neutral"
          onClick={() => updateMeasurementUnit(nextMeasurementUnit)}
        >{`Convert to ${getUnitLongName(nextMeasurementUnit)}`}</Button>
      </div>
      <MeasurementSettingField.Header isThereDependableFields={isThereDependableFields}>
        <div className="col-span-5">&nbsp;</div>
        <MeasurementFormBlockDisplayHeading heading="Block" />
        <MeasurementFormBlockDisplayHeading color="text-gray-700" heading="Finished" />
      </MeasurementSettingField.Header>
      <div className="flex flex-col gap-4">
        {fields?.map((field, index) => {
          if (field.basedOn) return null;

          const { isHalved } = field;
          const fieldName = `tweaks.${index}.value` as const;
          const tweak = watch(fieldName) || 0;
          const block = getBlockMeasurement(fits, fit, size, field);
          const blockValue = block?.value * (isHalved ? 2 : 1);
          const finished = blockValue + tweak * (isHalved ? 2 : 1);
          const blockRange = getBlockRange(block, field);

          const dependableFields = dependingFieldMapping[fieldName];
          const errorMessage = getErrorMessage(errors?.tweaks && errors.tweaks[index]?.value?.message, blockRange);

          return (
            <MeasurementSettingField.Container
              key={field.key}
              isExpandable={dependableFields && dependableFields.length > 0}
              isThereDependableFields={isThereDependableFields}
            >
              <div className="col-span-3 flex items-center">
                <div className="text-sm text-gray-500">{isHalved ? field.name.replace('Half', '') : field.name}</div>
              </div>
              <div className="col-span-2 flex">
                <Select
                  register={register(fieldName, {
                    valueAsNumber: true,
                    validate: () => {
                      if (finished < blockRange?.minRange) {
                        return 'min';
                      } else if (finished > blockRange?.maxRange) {
                        return 'max';
                      } else if (
                        (field.key === 'BB_SHORT_SLEEVE_SHIRT_SLEEVE_LENGTH_LEFT' || field.key === 'BB_SHORT_SLEEVE_SHIRT_SLEEVE_LENGTH_RIGHT') &&
                        shouldValidateSleeveLength &&
                        !checkBBIndiaSleeveLength({ garmentCat: garmentCategory, key: field.key })
                      ) {
                        return 'sleeve-missmatch';
                      }
                      return garmentCategory === GarmentCategory.ShirtV3 ? validateShirtFrontBackLength({ key: field.key }) : true;
                    },
                  })}
                  htmlProps={
                    garmentCategory == GarmentCategory.Jacket && field.key == 'JACKET_FIRST_BUTTON'
                      ? { defaultValue: '0', onChange: ({ currentTarget }) => handleTweakChange(currentTarget, field.key, index) }
                      : { defaultValue: '0' }
                  }
                >
                  {field.tweaks.map((num) => (
                    <option key={num} value={isHalved ? num / 2 : num}>
                      {`${convertFromServerUnit(num, decimalPrecision)} ${measurementUnitInfo.label}`}
                    </option>
                  ))}
                </Select>
                <div>
                  {field?.info ? (
                    <button
                      type="button"
                      className="self-center ml-5"
                      onClick={() => {
                        setActiveGarment(field);
                        setOpen(true);
                      }}
                    >
                      <Icon icon="info-stroke" width={12} height={12} />
                    </button>
                  ) : (
                    <div className="w-8" />
                  )}
                </div>
              </div>
              <MeasurementFormBlockDisplay
                showValue
                value={convertFromServerUnit(blockValue, decimalPrecision)}
                measurementUnitLabel={measurementUnitInfo.label}
              />
              {errorMessage ? (
                <Tooltip
                  content={<span className="text-white">{errorMessage}</span>}
                  className="col-span-1 cursor-pointer"
                  variant="dark"
                  contentWidth="150px"
                >
                  <MeasurementFormBlockDisplay
                    className="h-full"
                    showValue
                    value={convertFromServerUnit(finished, decimalPrecision)}
                    measurementUnitLabel={measurementUnitInfo.label}
                    color="text-red-500"
                  />
                </Tooltip>
              ) : (garmentCategory == GarmentCategory.Jacket && showFirstButtonTooltip && field.key == 'JACKET_FIRST_BUTTON') ||
                (garmentCategory == GarmentCategory.Jacket && getValues(`tweaks.${index}.value`) != 0 && field.key == 'JACKET_FIRST_BUTTON') ? (
                <Tooltip
                  content={
                    <span className="text-white">
                      Top-button measurement is based on block size of 1Btn/2Btn normal/peak:
                      <br /> <br />
                      <ul className="list-disc ml-4  space-y-2">
                        <li>For 3Btn/2.5Btn normal/peak: Top button measurement should be 9cm less than 1Btn/2Btn normal/peak lapel.</li>
                        <li>For 2.5Btn normal/peak: Top Button measurement should be 2.5cm less than 1Btn/2Btb normal/peak lapel.</li>
                        <li>For 6Btn/4Btn normal/peak: Top Button measurement should be 3cm greater than 1Btn/2Btn normal/peak lapel.</li>
                      </ul>
                    </span>
                  }
                  className="col-span-1 cursor-pointer"
                  variant="dark"
                  contentWidth="350px"
                >
                  <MeasurementFormBlockDisplay
                    className="h-full"
                    showWarning={true}
                    showValue
                    value={convertFromServerUnit(finished, decimalPrecision)}
                    measurementUnitLabel={measurementUnitInfo.label}
                    color="text-amber-500"
                  />
                </Tooltip>
              ) : (
                <MeasurementFormBlockDisplay
                  showValue
                  value={convertFromServerUnit(finished, decimalPrecision)}
                  measurementUnitLabel={measurementUnitInfo.label}
                  color="text-gray-700"
                />
              )}
              {dependableFields && dependableFields.length > 0 && (
                <MeasurementSettingField.Panel isThereDependableFields={isThereDependableFields}>
                  {dependableFields.map(({ fieldSetting: dependableFieldSetting, fieldName: dependableFieldName }) => {
                    const { isHalved } = dependableFieldSetting;
                    const dependableFieldBlock = getBlockMeasurement(fits, fit, size, dependableFieldSetting);
                    const dependableBlockValue = dependableFieldBlock?.value * (isHalved ? 2 : 1);
                    const dependableFinished = dependableBlockValue + tweak * (isHalved ? 2 : 1);

                    return (
                      <Fragment key={dependableFieldSetting.key}>
                        <div className="col-span-5 flex items-center">
                          <div className="text-sm text-gray-500">
                            {isHalved ? dependableFieldSetting.name.replace('Half', '') : dependableFieldSetting.name}
                          </div>
                        </div>
                        <div className="hidden">
                          <Input register={register(dependableFieldName)} htmlProps={{ hidden: true }} />
                        </div>
                        <MeasurementFormBlockDisplay
                          showValue
                          value={convertFromServerUnit(dependableBlockValue, decimalPrecision)}
                          measurementUnitLabel={measurementUnitInfo.label}
                        />
                        <MeasurementFormBlockDisplay
                          showValue
                          value={convertFromServerUnit(dependableFinished, decimalPrecision)}
                          measurementUnitLabel={measurementUnitInfo.label}
                          color="text-gray-700"
                        />
                      </Fragment>
                    );
                  })}
                </MeasurementSettingField.Panel>
              )}
            </MeasurementSettingField.Container>
          );
        })}
      </div>
      <SlideoverPanel
        isOpen={open}
        setIsOpen={setOpen}
        maxWidthCss="max-w-xl"
        showCancel={false}
        showExit={false}
        submitButton={<Button onClick={() => setOpen(false)}>Close</Button>}
      >
        {activeGarment && <GarmentInfo garment={activeGarment} />}
      </SlideoverPanel>
    </>
  );
};
